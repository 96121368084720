// ==========================================================================
// Colours
// ==========================================================================

$black: #141414;
$white: #f3f3f3;
$light-red: #de354c;
$dark-red: #932432;
$purple: #3c1874;
$gray: #283747;

// ==========================================================================
// Base Layout
// ==========================================================================
$baseUnit: 8px;
$maxWidth: 1224px;
$minWidth: 320px;
$mobileMax: 860px;