@import "../../scss/.critical.scss";

.header {
  text-align: center;
}

span {
  &.red-header-text {
    color: $light-red;
    font-weight: 600;
  }
}
