.image-aligner {
   display: flex;
   justify-content: flex-end;
   margin-top: 24px;

   @media (max-width: 500px) {
      justify-content: center;
      margin: 0 0 16px 0;
   }
}

.key-facts-container {
   display: flex;
   @media (max-width: 500px) {
      flex-direction: column;
   }
}

.list-group-item{
   border: transparent !important;
}
